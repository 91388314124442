/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { ThreeDots as Loader } from "react-loader-spinner";
import { API_URL, custom_axios_post } from "./Common";
import { useHistory } from "react-router-dom";
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledDialog = styled(Dialog)`
  align-items: baseline;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledTitleDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledMainDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
  margin-top: 10px;
  padding: 20px;
  border: 2px dotted #333;
  border-radius: 10px;
  background: #f8f8f8;
`;

function ShowPrintAlumni({ open, handleClose, honbuids }) {
  //const { state } = useContext(Store);
  const history = useHistory();
  useEffect(() => {}, []);
  //   const today = new Date();
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [localhonbuid, setLocalHonbuid] = React.useState("");
  //クリップボードにコピー関数
  const copyToClipboard = async () => {
    // eslint-disable-next-line no-undef
    await global.navigator.clipboard.writeText(localhonbuid);
  };
  const proc = async () => {
    let strhonbuids = honbuids
      .map((e) => {
        return e;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
    };
    const result = await custom_axios_post(API_URL + "/getQrStrings", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      for (let j = 0; j < result.data.qrlist.length; j++) {
        const viewstring = `${result.data.qrlist[j].name} 様

【インストール方法】
ご自身のスマートフォンのOSに合ったURLからインストールをお願いします
<iOSの場合>
https://apps.apple.com/jp/app/chatis/id6443533152

<Androidの場合>
https://play.google.com/store/apps/details?id=com.tis.chatapp&hl=ja


【ログイン方法】
Chatisのログイン画面にて、以下情報を入力してログインお願いします。
URL：https://chat-tis.com
ID：${result.data.qrlist[j].honbuid}
PASS：${result.data.qrlist[j].initpass}
                
以上、宜しくお願いします。`;

        setLocalHonbuid(viewstring);
      }

      setIsCompleted(true);
    }
  };

  useEffect(() => {
    if (open) {
      setIsCompleted(false);
      proc();
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">インストール情報</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {isCompleted == false ? (
          <StyledTitleDiv>
            <Loader type="ThreeDots" height="100" width="100" />
          </StyledTitleDiv>
        ) : (
          <StyledMainDiv>{localhonbuid}</StyledMainDiv>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Snackbar
          open={snackOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="コピーしました"
        ></Snackbar>
        <Tooltip title="クリップボードにコピー">
          <IconButton
            color="primary"
            aria-label="send message"
            onClick={() => {
              copyToClipboard();
              setSnackOpen(true);
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default ShowPrintAlumni;
