/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios, custom_axios_post } from "./Common";
import TextField from "@mui/material/TextField";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import ShowCommon from "./ShowCommon";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  overflow-y: hidden;
`;
const StyledFormControl = styled(FormControl)`
  margin: ${(props) => props.theme.spacing(1) + "px"};
  width: 200px;
  margin-top: 10px;
`;

function ShowCreateAlumniAccount({ open, handleClose, afterSaveProc }) {
  const history = useHistory();
  const [name, setName] = useState("");
  const [options] = useState([
    <MenuItem key="99999" value="99999">
      アルムナイ
    </MenuItem>,
  ]);
  const [selectedhp, setSelectedHp] = useState("99999");
  const [jobOptions, setJobOptions] = useState([]);
  const [selectedjob, setSelectedJob] = useState([]);
  const [jobopen, setJobOpen] = useState(false);
  const [hpopen, setHpOpen] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleSave = async () => {
    handleModalClose();

    const body = {
      name: name,
      hp: selectedhp,
      unit: "1",
      job: selectedjob.split(":")[0],
    };
    const result = await custom_axios_post(API_URL + "/addAlumniAccount", body);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
    if (result.status === 200) {
      setName("");
      afterSaveProc();
      handleClose(); /* 自身も閉じる */
      // setNewUser({ open: true, honbuid: result.data.honbuid });
    }
  };
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    //console.log("GetUser前", honbuid);
    const proc = async () => {
      let honbuid = localStorage.getItem("honbuid");
      //console.log("GetMultiSelect");
      if (open) {
        const result = await custom_axios(
          API_URL + `/GetMultiSelect?honbuid=${honbuid}&hpcodes=${selectedhp}&hascode=1`
        );
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        } else if (status === 200) {
          setJobOptions(
            result.data.jobs.map((e) => {
              return (
                <MenuItem key={e.jobcode} value={e.jobname}>
                  {e.jobname}
                </MenuItem>
              );
            })
          );
        }
      }
    };
    proc();
  }, [open]);

  useEffect(() => {
    //let ret = false;
    let okCnt = 0;

    if (name && name.length > 0) {
      okCnt++;
    }
    if (selectedhp && selectedhp.length > 0) {
      okCnt++;
    }
    if (selectedjob && selectedjob.length > 0) {
      okCnt++;
    }
    /* 必須入力箇所（3個）が入力できた場合は登録ボタンを押せるようにする */
    setIsVisible(okCnt == 3);
  }, [name, selectedhp]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">アルムナイユーザー作成</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <div className="question-create-wrap">
          <StyledFormControl required>
            <InputLabel>施設</InputLabel>
            <Select
              error={selectedhp.length == 0}
              open={hpopen}
              onClose={() => {
                setHpOpen(false);
              }}
              onOpen={() => {
                setHpOpen(true);
              }}
              value={selectedhp}
              onChange={(e) => {
                setSelectedHp(e.target.value);
              }}
            >
              {options}
            </Select>
          </StyledFormControl>
          <StyledFormControl required>
            <InputLabel>職種</InputLabel>
            <Select
              error={selectedjob.length == 0}
              open={jobopen}
              onClose={() => {
                setJobOpen(false);
              }}
              onOpen={() => {
                setJobOpen(true);
              }}
              value={selectedjob}
              onChange={(e) => {
                setSelectedJob(e.target.value);
              }}
            >
              {jobOptions}
            </Select>
          </StyledFormControl>
          <TextField
            required
            error={name == ""}
            label="名前"
            id="outlined-size-normal"
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ margin: "8px", width: "200px" }}
          />
        </div>
        <ShowCommon
          open={openModal}
          title="作成しますか？"
          handleClose={handleModalClose}
          handleCallBack={handleSave}
        ></ShowCommon>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
          startIcon={<SaveOutlinedIcon />}
        >
          作成
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowCreateAlumniAccount;
